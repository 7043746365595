import { styled, tableCellClasses, TableRow as MuiTableRow, TableRowProps } from '@mui/material';

interface Props extends TableRowProps {
  backgroundColor?: string;
  rounded?: boolean;
}

const StyledTableRow = styled(MuiTableRow, {
  shouldForwardProp: (prop) => {
    if (prop === 'backgroundColor' || prop === 'rounded') {
      return false;
    }
    return true;
  },
})<Props>(({ rounded, backgroundColor, theme }) => ({
  [`.${tableCellClasses.root}`]: {
    '&:first-of-type': {
      borderRadius: rounded ? `12px 0 0 12px` : 0,
    },
    '&:last-of-type': {
      borderRadius: rounded ? `0 12px 12px 0` : 0,
    },
    background: backgroundColor ?? theme.palette.common.white,
    border: 'none',
    boxShadow: theme.customShadows.tableRow,
  },
}));

export const TableRow = ({ ...rest }: Props) => <StyledTableRow {...rest} />;
