/* eslint-disable @typescript-eslint/no-shadow */
import { grey } from '@mui/material/colors';
import { createTheme, Theme } from '@mui/material/styles';

type Dimensions = {
  appBarHeight: string;
  pageHeight: string;
  mobileStatCardWidth: string;
  statCardWidth: string;
  statCardHeight: string;
  cardWidth: string;
  sidebarWidth: string;
  mobileCardWidth: string;
  bottomNavigationHeight: string;
};
declare module '@mui/material/styles' {
  interface Theme {
    dimensions: Dimensions;
    backgrounds: {
      page: string;
    };
    customShadows: {
      bottomNavigation: string;
      main: string;
      sidebar: string;
      tableRow: string;
      front: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    dimensions: Dimensions;
    backgrounds: {
      page: string;
    };
    customShadows: {
      bottomNavigation: string;
      main: string;
      sidebar: string;
      tableRow: string;
      front: string;
    };
  }

  interface Gradient {
    rainbow: string;
    lightTeal: string;
    teal: string;
    darkPurple: string;
    darkGreen: string;
  }

  interface Palette {
    purple: Palette['primary'];
    limeGreen: Palette['primary'];
    green: Palette['primary'];
    blue: Palette['primary'];
    warningRed: Palette['primary'];
    mintGreen: Palette['primary'];
    gradient: Gradient;
  }

  interface PaletteOptions {
    textColor: PaletteOptions['primary'];
    purple: PaletteOptions['primary'] & { lighter: string };
    limeGreen: PaletteOptions['primary'];
    warningRed: PaletteOptions['primary'];
    mintGreen: PaletteOptions['primary'];
    red: PaletteOptions['primary'];
    blue: PaletteOptions['primary'];
    teal: PaletteOptions['primary'];
    gradient: Gradient;
    gray: PaletteOptions['primary'];
    notifications: {
      yellow: string;
      red: string;
      green: string;
      orange: string;
    };
  }
}

export type { Theme };

const theme = createTheme({
  backgrounds: {
    page: grey[100],
  },
  breakpoints: {
    values: {
      lg: 1280,
      md: 600,
      sm: 360,
      xl: 1600,
      xs: 0,
    },
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        rounded: {
          borderRadius: '14px',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-selected': {
            background: theme.palette.gradient.darkGreen,
            color: theme.palette.common.white,
          },
          borderRadius: '10px',
          height: 50,
          margin: '14px 0 0',
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.common.white,
          borderRadius: '12px',
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.common.black,
          fontSize: 20,
          margin: '15px 0',
          textTransform: 'none',
          whiteSpace: 'pre-line',
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiTabs-indicator': {
            height: 4,
            zIndex: 10,
          },
          '&::after': {
            backgroundColor: theme.palette.grey[300],
            bottom: 0,
            content: '""',
            height: 4,
            left: 0,
            position: 'absolute',
            right: 0,
            width: '100%',
            zIndex: 1,
          },
          position: 'relative',
        }),
      },
    },
  },
  customShadows: {
    bottomNavigation: '0px -2px 5px #00000033',
    front: 'rgba(0, 0, 0, 0.1) 2px 5px 10px;',
    main: '0px 4px 6px #00000014',
    sidebar: '18px 4px 35px #0000000D',
    tableRow: '0px 6px 0px #0000000A',
  },
  dimensions: {
    appBarHeight: '64px',
    bottomNavigationHeight: '70px',
    cardWidth: '23.5rem',
    mobileCardWidth: '14rem',
    mobileStatCardWidth: '170px',
    pageHeight: '100vh',
    sidebarWidth: '240px',
    statCardHeight: '112px',
    statCardWidth: '320px',
  },
  palette: {
    blue: {
      dark: '#002138',
      main: '#266FC7',
    },
    gradient: {
      darkGreen: 'radial-gradient(56.19% 124.63% at 51.15% 43.27%, #0F8F84 0%, #165E88 100%)',
      darkPurple: 'linear-gradient(90deg, #6418C3 0%, #AB18C3 100%)',
      lightTeal: 'linear-gradient(to right, #00D0BE, #92FE9D)',
      rainbow: 'linear-gradient(90deg, #FDE618 0%, #12F98D 18%, #1CBBFF 43%, #53DEBD 84%, #53DEBD 100%)',
      teal: 'linear-gradient(164deg, #0076D0 0%, #94FDAD 100%)',
    },
    gray: {
      main: '#F5F5F5',
    },
    info: {
      light: '#646464',
      main: '#000000',
    },
    limeGreen: {
      dark: '#38E25D',
      light: '#E8FFEC',
      main: '#D8FFE0',
    },
    mintGreen: {
      dark: '#00D0BE',
      light: '#BBF2DF',
      main: '#A6EED5',
    },
    notifications: {
      green: '#94FDAD',
      orange: '#FFCB91',
      red: '#FF6161',
      yellow: '#FFF68C',
    },
    primary: {
      dark: '#00A698',
      light: '#44D0BE',
      main: '#00D0BE',
    },
    purple: {
      dark: '#4B1644',
      light: '#CA90BB',
      lighter: '#F6EEFF',
      main: '#6418C3',
    },
    red: {
      main: '#DC3472',
    },
    secondary: {
      light: '#0CBFE8',
      main: '#0B7FDE',
    },
    teal: {
      main: '#35C691',
    },
    textColor: {
      light: '#A5A5A5',
      main: '#C2C2C2',
    },
    warningRed: {
      dark: '#ff0000',
      light: '#FFB1B3',
      main: '#FAA0A0',
    },
  },
  typography: {
    fontFamily: 'Comfortaa, sans-serif',
    h1: {
      fontFamily: 'Nunito, sans-serif',
    },
    h2: {
      fontFamily: 'Nunito, sans-serif',
    },
    h3: {
      fontFamily: 'Nunito, sans-serif',
    },
    h4: {
      fontFamily: 'Nunito, sans-serif',
    },
    h5: {
      fontFamily: 'Nunito, sans-serif',
    },
    h6: {
      fontFamily: 'Nunito, sans-serif',
    },
  },
});

export default theme;
