import { ListItemButton, ListItemButtonProps, ListItemIcon, ListItemText } from '@mui/material';
import { ReactElement } from 'react';

type ExtendedProps = {
  icon?: ReactElement;
};

type Props<C extends React.ElementType> = ListItemButtonProps<C, { component?: C } & ExtendedProps>;

const SidebarItem = <C extends React.ElementType = 'div'>({ children, icon, ...rest }: Props<C>) => {
  return (
    <ListItemButton {...rest}>
      {icon && <ListItemIcon sx={{ color: rest.selected ? 'common.white' : 'common.black' }}>{icon}</ListItemIcon>}
      <ListItemText>{children}</ListItemText>
    </ListItemButton>
  );
};

export default SidebarItem;
