import { BottomNavigationAction, BottomNavigationActionProps } from '@mui/material';

type Props<C extends React.ElementType> = BottomNavigationActionProps<C, { component?: C }>;

const BottomNavigationItem = <C extends React.ElementType>({ label, icon, ...rest }: Props<C>) => {
  return (
    <BottomNavigationAction
      sx={({ palette, spacing }) => ({
        '& .MuiSvgIcon-root': {
          height: spacing(4),
          padding: spacing(0.5),
          width: spacing(4),
        },
        '& span': {
          fontSize: '14px',
        },

        '&.Mui-selected': {
          '& .MuiSvgIcon-root': {
            background: palette.gradient.darkGreen,
            borderRadius: '14px',
            color: palette.common.white,
          },
          color: palette.common.black,
        },
        color: palette.common.black,
      })}
      {...rest}
      label={label}
      icon={icon}
    />
  );
};

export default BottomNavigationItem;
