import { Tooltip } from '@mui/material';
import MuiAvatar from '@mui/material/Avatar';
import MuiBadge from '@mui/material/Badge';
import { ReactElement } from 'react';

import { getNameInitials } from '../../utils';
import BadgeContent, { BadgeProps } from './BadgeContent';
import { Color, colorsMap } from './colorsMap';

type Size = 'small' | 'large' | 'medium' | 'xlarge' | 'xxlarge';

type SizesMap = {
  [key in Size]: number;
};

const sizesMap: SizesMap = {
  large: 88,
  medium: 52,
  small: 32,
  xlarge: 120,
  xxlarge: 195,
};

interface Props {
  shape?: 'circular' | 'rounded';
  size?: Size;
  withGradient?: boolean;
  name: string;
  src?: string;
  bgColor?: Color;
  badge?: BadgeProps;
  tooltip?: boolean;
}

const Avatar = ({ shape, size, withGradient, name, src, bgColor, badge, tooltip }: Props): ReactElement => {
  const sizeInPx = sizesMap[size || 'small'];
  const backgroundColor = colorsMap[bgColor || 'green'];

  return (
    <MuiBadge
      overlap="rectangular"
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      invisible={!badge}
      badgeContent={badge ? <BadgeContent content={badge.content} color={badge.color} size={sizeInPx / 3} /> : null}
      sx={{
        '& .MuiBadge-badge': {
          bottom: sizeInPx / 6,
        },
        aspectRatio: '1/1',
        maxHeight: sizeInPx,
        maxWidth: sizeInPx,
        width: '100%',
      }}
    >
      <Tooltip title={tooltip ? name : ''}>
        <MuiAvatar
          variant={shape}
          src={src}
          sx={{
            background: ({ palette }) => (withGradient ? palette.gradient.darkGreen : bgColor),
            backgroundColor,
            fontSize: sizeInPx / 2.5,
            height: '100%',
            width: '100%',
          }}
        >
          {getNameInitials(name)}
        </MuiAvatar>
      </Tooltip>
    </MuiBadge>
  );
};

Avatar.defaultProps = {
  bgColor: 'green',
  shape: 'rounded',
  size: 'medium',
  tooltip: false,
};

export default Avatar;
