import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

import { Loader } from '../Loader';
import { StyledButtonBase } from './styles';
import { ButtonProps } from './types';

const Button = ({
  variant,
  children,
  disabled,
  loading = false,
  noWrap,
  color,
  size,
  fullWidth,
  startIcon,
  endIcon,
  ...rest
}: ButtonProps) => {
  return (
    <StyledButtonBase
      disabled={disabled || loading}
      color={color}
      size={size}
      variant={variant}
      fullWidth={fullWidth}
      noWrap={noWrap}
      {...rest}
    >
      {!loading ? (
        <Box display="flex" alignItems="center" gap={1}>
          {startIcon}
          <Typography fontWeight="bold">{children}</Typography>
          {endIcon}
        </Box>
      ) : (
        <Loader data-testid="loader" sx={{ color: grey[300] }} size={30} />
      )}
    </StyledButtonBase>
  );
};

export default Button;
